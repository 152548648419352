<!--
 * @Descripttion:
 * @version:
 * @Author: 李宗哲
 * @Date: 2020-03-09 17:12:51
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-05-18 15:02:17
 -->
<template>
  <div>
    <div class="topimgbg">
      <img src="@/assets/nh/nhwx-001.jpg"
           alt
           class="mainpic" />
      <div class="contentbox">
        <div class="article"
             v-for="(item,index) in datalist"
             :key="index">
          <div class="shangbu">
            <p class="biaoti">{{item.prodname}}</p>
            <p class="miaoshu">{{item.pdesc}}</p>
            <div class="zitiyanse">
              <p class="shuzi"
                 v-if="item.minprice"
                 :style="`color: ${colorprimary};`">
                ￥
                <span class="shuziys"
                      :style="`color: ${colorprimary};`">{{item.minprice}}</span>
                <span class="houzhui">起</span>
              </p>
            </div>
          </div>
          <div class="xiabanbu">
            <div class="zuobian"
                 @click="deletecp(item,index)">
              <img src="@/assets/nh/nhwxmd-02.png"
                   alt
                   class="sctb" />
              <span class="caozuosc"
                    :style="`color: ${colorprimary};`">删除</span>
            </div>
            <div class="youbian">
              <div class="zdsyxy"
                   v-if="index!='0'">
                <img src="@/assets/nh/nhwxmd-03.png"
                     alt
                     class="zdsyxytb" />
                <span class="caozuozd"
                      @click="zhiding (item, index)">置顶</span>
              </div>
              <div class="zdsyxy"
                   v-if="index!='0'">
                <img src="@/assets/nh/nhwxmd-04.png"
                     alt
                     class="syxytb" />
                <span class="caozuo"
                      @click="shangyi (item, index)">上移</span>
              </div>
              <div class="zdsyxy">
                <img src="@/assets/nh/nhwxmd-05.png"
                     alt
                     class="syxytb" />
                <span class="caozuo"
                      @click="xiayi (item, index)">下移</span>
              </div>
            </div>
          </div>
        </div>
        <div class="tjcp">
          <md-button @click="showSelector"
                     class="classtjcp"
                     :style="`background-image: linear-gradient(to right, #fa5763, ${colorprimary});`">
            添加产品
            <md-icon name="arrow-right"
                     size="sm"
                     style="color:#ffffff;"></md-icon>
          </md-button>
          <md-popup v-model="isSelectorShow"
                    position="bottom"
                    class="popupmd">
            <md-popup-title-bar title="产品"
                                ok-text="确定"
                                cancel-text="取消"
                                large-radius
                                @confirm="onSelectorConfirm"
                                @cancel="hidePopUp"></md-popup-title-bar>
            <div class="searchbox">
              <div class="searchline">
                <button class="btnicon"
                        style="background-color: #f2f2f2;">
                  <md-icon name="search"
                           size="lg"
                           color="#D6D6D6"></md-icon>
                </button>
                <input type="text"
                       placeholder="搜索产品名称"
                       v-model="searchValue"
                       v-on:focus="getFocus"
                       class="inputss" />
                <button class="btnclear"
                        @click="clearinput"
                        v-show="isclear"
                        style="background-color: #f2f2f2;">
                  <md-icon name="fail"
                           size="lg"
                           color="#666"></md-icon>
                </button>
              </div>
              <div class="searchTxt"
                   @click="yourmethod">搜索</div>
            </div>
            <div style="background-color: #fff;">
              <md-field style="height: 50vh;overflow: auto;"
                        class="fieldmd">
                <md-check-list v-model="selectorValue"
                               iconPosition="right"
                               :options="tabdata">
                  <template slot-scope="{ option, index}">
                    <div class="selector-item-body">
                      <div class="display">
                        <div class="selector-item-left">
                          <img :src="ossurl+option.iconhttp"
                               alt
                               class="fuxuanliebiao" />
                        </div>
                        <div class="selector-item-content">
                          <p class="selector-item-title"
                             v-text="option.text"></p>
                          <p class="selector-item-brief"
                             v-text="`${option.pdesc}`"></p>
                        </div>
                      </div>
                    </div>
                  </template>
                </md-check-list>
              </md-field>
            </div>
          </md-popup>
        </div>
      </div>
    </div>
    <div class="dibu">
      <div class="leftbianji"
           @click="quxiaojump">
        <span class="leftziti">取消</span>
      </div>
      <div class="rightfenxiang"
           @click="cpadd"
           :style="`backgroundColor: ${colorprimary};`">
        <span class="rightziti">保存</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Check, CheckGroup } from "mand-mobile";
import { getStorage } from "@/lib/util";
import { showListsd, showListsdadd } from "@/api/agent/agent/index";
export default {
  name: "check-demo",
  components: {
    [Check.name]: Check,
    [CheckGroup.name]: CheckGroup
  },
  data () {
    return {
      colorprimary: "",
      searchValue: "",
      isclear: false,

      isSelectorShow: false,
      data: [],
      tabdata: [],
      selectorValue: [],
      prodcode: "",
      listlist: [],
      datalist: [],
      height: "",
      ossurl: "",
      checked: false
    };
  },
  created () {
    this.colorprimary = getStorage("theme", "");
    this.ossurl = getStorage("ossurl", "");
    this.getList();
  },
  mounted () {
    $(document).on("focusout", function () {
      // 软键盘收起的事件处理
      window.scroll(0, 0);
    });
  },
  methods: {
    hidePopUp () {
      this.isSelectorShow = false;
    },
    yourmethod () {
      this.tabdata = [];
      this.geitdata();
    },
    getFocus () {
      this.isclear = true;
    },
    clearinput () {
      this.searchValue = "";
      this.isclear = false;
    },
    showSelector () {
      this.tabdata = [];
      this.selectorValue = [];
      for (var rr in this.datalist) {
        this.selectorValue.push(this.datalist[rr].prodcode);
      }
      this.geitdata();
    },
    geitdata () {
      let data = {
        baseid: "",
        prodtype: "",
        search: this.searchValue,
        type: "2"
      };
      showListsd(data)
        .then(res => {
          this.data = res.data.data.list;
          for (var a = 0; a < this.data.length; a++) {
            let arr = {
              value: this.data[a].prodcode,
              text: this.data[a].prodname,
              pdesc: this.data[a].pdesc,
              iconhttp: this.data[a].iconhttp
            };
            this.tabdata.push(arr);
          }
        })
        .finally(() => {
          this.isSelectorShow = true;
        });
    },
    onSelectorConfirm (array) {
      this.datalist = [];
      // this.selectorValue = array;
      for (var w in this.selectorValue) {
        for (var i in this.data) {
          if (this.selectorValue[w] == this.data[i].prodcode) {
            this.datalist.push(this.data[i]);
          }
        }
      }
      this.isSelectorShow = false;
    },
    zhiding (item, index) {
      this.datalist.unshift(this.datalist[index]);
      this.datalist.splice(index + 1, 1);
    },
    shangyi (item, index) {
      this.datalist.splice(index - 1, 0, item);
      this.datalist.splice(index + 1, 1);
    },
    xiayi (item, index) {
      this.datalist.splice(index + 2, 0, item);
      this.datalist.splice(index, 1);
    },
    quxiaojump () {
      this.$router.push(`/microshop?`);
    },
    getList () {
      let data = {
        baseid: "",
        prodtype: "",
        search: "",
        type: "1"
      };
      showListsd(data).then(res => {
        this.datalist = res.data.data.list;
      });
    },
    deletecp (val, index) {
      this.datalist.splice(index, 1);
      this.selectorValue = [];
      for (var rr in this.datalist) {
        this.selectorValue.push(this.datalist[rr].prodcode);
      }
    },
    cpadd () {
      this.listlist = [];
      for (var q = 0; q < this.datalist.length; q++) {
        let arrt = {
          prodcode: this.datalist[q].prodcode
        };
        this.listlist.push(arrt);
      }
      showListsdadd(this.listlist).then(res => {
        this.$router.push(`/microshop?`);
      });
    }
  }
};
</script>

<style scoped lang="stylus" >
.topimgbg {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 10vh;
}

.mainpic {
  height: auto;
}

.contentbox {
  padding: 0.5rem 0.5rem 0;
}

.dibu {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99;
  display: flex;
  width: 100%;
  height: 1.2rem;
}

.leftbianji {
  width: 50%;
  padding: 10px 40px;
  border-right: 0.02rem solid #cccccc;
  color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.rightfenxiang {
  width: 50%;
  padding: 0.1rem 0.4rem;
  display: flex;
  align-items: center;
  // background-color: color-primary;
}

.leftziti {
  margin-left: 0.1rem;
  color: #989898;
  text-align: center;
  width: 100%;
}

.rightziti {
  margin-left: 0.1rem;
  color: rgb(255, 255, 255);
  text-align: center;
  width: 100%;
}

.article {
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  height: 3.3rem;
  background: #f5f5f5;
  border-radius: 0.1rem;
}

.shangbu {
  width: 100%;
  line-height: 0.6rem;
}

.biaoti {
  /* font-weight: 600; */
  font-family: PingFang-SC-Bold;
  color: #383838;
  height: 0.6rem;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 不换行 */
  text-overflow: ellipsis; /* 超出部分文字以...显示 */
}

.miaoshu {
  height: 0.6rem;
  font-size: 0.3rem;
  color: #989898;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 不换行 */
  text-overflow: ellipsis; /* 超出部分文字以...显示 */
}

.shuzi {
  // color: color-primary;
  font-family: OPPOSans-B;
  font-size: 0.3rem;
}

.shuziys {
  font-weight: 600;
  font-size: 0.35rem;
  margin-right: 0.1rem;
  // color: color-primary;
  font-family: OPPOSans-B;
}

.zitiyanse {
  color: rgb(77, 77, 77);
  margin-top: 0.2rem;
  height: 0.6rem;
}

.xiabanbu {
  display: flex;
  justify-content: space-between;
  margin-top: 0.4rem;
}

.zuobian {
  width: 30%;
  display: flex;
  height: 0.4rem;
  flex-direction: row;
}

.youbian {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.caozuo {
  font-size: 0.3rem;
  color: #989898;
}

.caozuosc {
  font-size: 0.3rem;
  // color: color-primary;
}

.caozuozd {
  font-size: 0.3rem;
  color: #4ea6e6;
}

.input-red {
  width: 50%;
  font-size: 0.3rem;
  height: 1rem;
  line-height: 1rem;
  border: 1px solid rgb(247, 188, 189);
  // color: color-primary;
  border-radius: 0.5rem;
  text-align: center;
  margin: auto;
}

.tjcp {
  width: 50%;
  margin: auto;
}

.classtjcp {
  color: #ffffff;
  // background-image: linear-gradient(to right, #fa5763, color-primary);
  border-radius: 0.5rem;
}

/deep/ .md-cell-item-title {
  width: 7rem;
  text-align: left;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 不换行 */
  text-overflow: ellipsis; /* 超出部分文字以...显示 */
}

/deep/.md-field-item {
  width: 50%;
  margin: auto;
}

/deep/.md-field-item-title {
  margin-left: 0.5rem;
  margin-right: 0;
}

.houzhui {
  color: #989898;
  font-size: 0.3rem;
}

.sctb {
  width: 0.4rem;
  height: 100%;
  margin-right: 0.05rem;
}

.zdsyxy {
  display: flex;
  height: 0.4rem;
  flex-direction: row;
}

.zdsyxytb {
  width: 0.35rem;
  height: 100%;
  margin-right: 0.1rem;
}

.syxytb {
  width: 0.35rem;
  height: 100%;
}

.fuxuanliebiao {
  width: 1.5rem;
  height: 1.5rem;
}

.display {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.selector-item-content {
  margin-left: 0.3rem;
}

.selector-item-left {
  width: 1.5rem;
  height: 1.5rem;
}

.selector-item-title {
  text-align: left;
  color: #383838;
  margin-bottom: 0.1rem;
  width: 5.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selector-item-brief {
  text-align: left;
  font-size: 0.3rem;
  color: #989898;
  width: 5.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/deep/.md-icon.icon-font.md {
  font-size: 0.5rem;
}

/deep/.md-selector-container {
  padding-bottom: 1rem;
}

.searchline {
  border-radius: 0.5rem;
  background: #f2f2f2;
  padding: 0 0.5rem;
  width: 80%;
}

.inputss {
  line-height: 1rem;
  height: 1rem;
  background-color: #f2f2f2;
  margin-left: 0.3rem;
  margin-right: 0.2rem;
  width: 75%;
  font-size: 0.35rem;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
}

.searchTxt {
  color: #989898;
  margin-left: 0.3rem;
}

.searchbox {
  display: flex;
  align-items: center;
  padding: 0.3rem 0 0 0.7rem;
  background-color: #fff;
  margin-bottom: 0.3rem;
}

.popupmd /deep/.md-popup-box {
  background-color: #fff;
}

.fieldmd {
  margin: 0.4rem 0.4rem;
}

.fieldmd /deep/.md-cell-item-body {
  padding-top: 0;
  margin-top: 0.32rem;
  margin-bottom: 0.32rem;
}
</style>
